<template>
    <div
        class="flex flex-col lg:flex-row p-8 justify-between items-start lg:items-stretch w-full"
    >
        <div class="w-full flex flex-col lg:flex-row items-start lg:items-top">
            <div class="px-3">
                <label
                    for="search"
                    class="block text-sm font-medium text-gray-700"
                >
                    {{ $t('admin.search.name') }}
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                    <span
                        class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                    >
                        <IconSearch class="stroke-current text-blue-600 h-4" />
                    </span>
                    <input
                        @keyup="onFilterChange({ title: filterSearch })"
                        type="text"
                        v-model="filterSearch"
                        name="search"
                        id="search"
                        autocomplete="search"
                        class="flex-1 focus:ring-blue-500 focus:border-blue-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                    />
                </div>
            </div>
            <div class="px-3">
                <label
                    for="etat"
                    class="block text-sm font-medium text-gray-700"
                >
                    {{ $t('admin.state') }}
                </label>
                <multiselect
                    v-model="filterEtat"
                    :options="companiesFilters.formTypes"
                    :multiple="true"
                    :close-on-select="true"
                    :select-label="value"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :show-labels="false"
                    @input="onFilterChangeMultiSelect('etat', filterEtat)"
                >
                    <span slot="noResult">{{ $t('admin.no_element') }}</span>
                </multiselect>
            </div>
        </div>
        <router-link to="/admin/companies/create">
            <button
                class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
                {{ $t('companies.create_company') }}
            </button>
        </router-link>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
    name: 'CompaniesFilters',
    components: {
        Multiselect
    },
    data() {
        return {
            filterSearch: '',
            filterEtat: '',
            loading: false,
            filters: {},
            value: ''
        }
    },
    computed: {
        ...mapState('admin', {
            companies: (state) => state.companies,
            config: (state) => state.companiesConfig,
            companiesFilters: (state) => state.companiesFilters
        })
    },
    created() {},
    methods: {
        ...mapActions('admin', ['getCompanies']),
        onFilterChange(currentFilter) {
            Object.keys(currentFilter).forEach((element) => {
                this.filters[element] = currentFilter[element]
            })
            this.config.page = 1
            this.config.filters = this.filters

            this.loadAsyncData()
        },
        onFilterChangeMultiSelect(element, filter) {
            var transformedFilter = Array()
            var i = 0
            for (i in filter) {
                transformedFilter[i] = filter[i].id
            }
            filter = {
                [element]: transformedFilter.toString()
            }
            this.onFilterChange(filter)
        },
        loadAsyncData() {
            this.loading = true
            this.getCompanies().then(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style></style>
