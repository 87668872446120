<template>
  <main id="js-page-content" class="page-content" role="main">
    <div class="moverflow-x-auto">
      <div
        class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white"
      >
        <TabCompanies v-if="companies"></TabCompanies>
      </div>
    </div>
  </main>
</template>

<script>
import TabCompanies from '@/components/Model/Admin/TabCompanies'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'AdminCompanies',
  components: {
    TabCompanies,
  },
  data() {
    return {}
  },
  created() {
    this.getCompanies()
    this.getCompaniesFilters()
  },
  computed: {
    ...mapState('admin', {
      companies: (state) => state.companies,
    }),
  },
  methods: {
    ...mapActions('admin', ['getCompanies', 'getCompaniesFilters']),
  },
}
</script>
