<template>
    <section>
        <CompaniesFilters />
        <b-table
            class="table-striped overflow-x-scroll"
            backend-sorting
            :data="companies.data"
            :striped="true"
            :mobile-cards="true"
            :sticky-header="false"
            @sort="onSort"
        >
            <b-table-column
                sortable
                field="id"
                label="ID"
                width="40"
                numeric
                v-slot="props"
            >
                {{ props.row.id }}
            </b-table-column>
            <b-table-column
                sortable
                field="form.name"
                label="Nom"
                v-slot="props"
            >
                {{ props.row.name }}
            </b-table-column>
            <b-table-column
                sortable
                field="status_id"
                label="État"
                v-slot="props"
            >
                <button
                    @click.prevent="displayStatus(props.row.id)"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dropdown-toggle"
                    :class="{ normal: props.row.status.id === 4 }"
                >
                    {{ props.row.status.name }}
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a
                        v-for="status in statuses"
                        :key="status.id"
                        class="dropdown-item d-flex"
                        @click="companyStatus(props.row.id, status.id)"
                    >
                        <span class="mr-auto">{{ status.name }}</span>
                        <span
                            v-if="status.id === props.row.status.id"
                            class="ml-auto status status-success align-items-center"
                        ></span>
                    </a>
                </div>
            </b-table-column>
            <b-table-column label="Actions" v-slot="props">
                <router-link
                    :to="`/admin/companies/${props.row.id}`"
                    class="inline-flex items-center px-3 py-2 -mt-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    <IconSearch class="h-4 w-4" />
                </router-link>
                <router-link
                    :to="`/admin/companies/${props.row.id}/edit`"
                    class="inline-flex items-center px-3 py-2 ml-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    <IconModif class="h-4 w-4" />
                </router-link>
            </b-table-column>
        </b-table>

        <Pagination
            v-if="companies"
            :total-results="companies.total"
            :total-pages="Math.ceil(companies.total / companies.per_page)"
            :per-page="companies.per_page"
            :current-page="companies.current_page"
            @pagechanged="onPageChange"
        />
    </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Pagination from '@/components/Model/Post/Pagination'
import CompaniesFilters from '@/components/Model/Admin/CompaniesFilters'

export default {
    name: 'TabCompanies',
    components: {
        Pagination,
        CompaniesFilters
    },
    data: function () {
        return {}
    },
    computed: {
        ...mapState('admin', {
            companies: (state) => state.companies,
            config: (state) => state.companiesConfig,
            statuses: (state) => state.statuses
        })
    },
    methods: {
        ...mapActions('admin', ['updateStatus', 'getCompany']),
        onSort(field, order) {
            this.config.page = 1
            this.config.sort = field
            this.config.sortDirection = order
        },
        onPageChange(page) {
            this.config.page = page
        },
        companyStatus(id, status_id) {
            var updatedStatus = {
                id,
                status_id
            }
            this.updateStatus(updatedStatus).then(() => {
                this.$store.state.admin.companies.data[id - 1].status =
                    this.statuses[status_id - 1]
                this.$store.state.admin.company.status =
                    this.statuses[status_id - 1]
            })
        },
        displayStatus(id) {
            this.getCompany(id)
        }
    }
}
</script>
