<template>
    <div
        class="bg-white px-4 py-5 flex items-center justify-between border-t border-gray-200 sm:px-6"
    >
        <div class="flex-1 flex justify-between sm:hidden">
            <a
                href="#"
                @click.prevent="onClickPreviousPage"
                :class="isInFirstPage ? 'disabled' : ''"
                :disabled="isInFirstPage"
                class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
                {{ $t('post.pagination.previous') }}
            </a>
            <a
                href="#"
                @click.prevent="onClickNextPage"
                :class="isInLastPage ? 'disabled' : ''"
                :disabled="isInLastPage"
                class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
                {{ $t('post.pagination.next') }}
            </a>
        </div>
        <div
            class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between"
        >
            <div>
                <p class="text-sm text-gray-700">
                    {{ $t('post.pagination.elements') }}
                    <span class="font-medium">{{
                        currentPage * perPage - perPage + 1
                    }}</span>
                    {{ $t('post.pagination.at') }}
                    <span class="font-medium">{{
                        currentPage * perPage >= totalResults
                            ? totalResults
                            : currentPage * perPage
                    }}</span>
                    {{ $t('post.pagination.on') }}
                    <span class="font-medium">{{ totalResults }}</span>
                    {{ $t('post.pagination.results') }}
                </p>
            </div>
            <div>
                <nav
                    class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination"
                >
                    <a
                        href="#"
                        @click.prevent="onClickPreviousPage"
                        :class="isInFirstPage ? 'disabled' : ''"
                        :disabled="isInFirstPage"
                        class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                        <span class="sr-only">{{
                            $t('post.pagination.previous')
                        }}</span>
                        <IconChevronLeft class="h-5" />
                    </a>
                    <a
                        :key="page.id"
                        v-for="page in pages"
                        href="#"
                        @click.prevent="onClickPage(page.name)"
                        :disabled="page.isDisabled"
                        class="relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                        :class="{
                            'hover:bg-gray-50': !isPageActive(page.name),
                            'border-gray-300': !isPageActive(page.name),
                            'text-gray-500': !isPageActive(page.name),
                            'bg-blue-50': isPageActive(page.name),
                            'border-blue-500': isPageActive(page.name),
                            'text-blue-600': isPageActive(page.name)
                        }"
                        >{{ page.name }}
                    </a>
                    <a
                        href="#"
                        @click.prevent="onClickNextPage"
                        :class="isInLastPage ? 'disabled' : ''"
                        :disabled="isInLastPage"
                        class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                        <span class="sr-only">{{
                            $t('post.pagination.next')
                        }}</span>
                        <IconChevronRight class="h-5" />
                    </a>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import IconChevronLeft from '@/components/icons/iconChevronLeft.vue'
import IconChevronRight from '@/components/icons/iconChevronRight.vue'
export default {
    name: 'pagination',
    components: {
        IconChevronLeft,
        IconChevronRight
    },
    props: {
        maxVisibleButtons: {
            type: Number,
            required: false,
            default: 3
        },
        totalResults: {
            type: Number,
            required: true
        },
        totalPages: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            required: true
        },
        currentPage: {
            type: Number,
            required: true
        }
    },
    computed: {
        isInFirstPage() {
            return this.currentPage === 1
        },
        isInLastPage() {
            if (this.totalPages === 0) {
                return true
            }
            return this.currentPage === this.totalPages
        },
        startPage() {
            // When on the first page
            if (this.currentPage === 1) {
                return 1
            }
            // When on the last page
            if (this.totalPages < this.maxVisibleButtons) {
                return 1
            }
            if (this.currentPage === this.totalPages) {
                return this.totalPages - this.maxVisibleButtons + 1
            }
            // When in between
            return this.currentPage - 1
        },
        endPage() {
            if (this.totalPages === 0) {
                return 1
            }
            if (this.totalPages < this.maxVisibleButtons) {
                return this.totalPages
            }
            return Math.min(
                this.startPage + this.maxVisibleButtons - 1,
                this.totalPages
            )
        },
        pages() {
            const range = []
            for (let i = this.startPage; i <= this.endPage; i++) {
                range.push({
                    name: i,
                    isDisabled: i === this.currentPage
                })
            }
            return range
        }
    },
    methods: {
        onClickFirstPage() {
            if (this.isInFirstPage) {
                return false
            }
            this.$emit('pagechanged', 1)
        },
        onClickPreviousPage() {
            if (this.isInFirstPage) {
                return false
            }
            this.$emit('pagechanged', this.currentPage - 1)
        },
        onClickPage(page) {
            this.$emit('pagechanged', page)
        },
        onClickNextPage() {
            if (this.isInLastPage) {
                return false
            }
            this.$emit('pagechanged', this.currentPage + 1)
        },
        onClickLastPage() {
            if (this.isInLastPage) {
                return false
            }
            this.$emit('pagechanged', this.totalPages)
        },
        isPageActive(page) {
            return this.currentPage === page
        }
    }
}
</script>
